import React from "react"
import { Link } from "gatsby"

const BackBtn = props => {
  return (
    <p style={{ marginTop: "2rem" }}>
      <Link to={props.destination} className="btn">
        {props.text}
      </Link>
    </p>
  )
}

export default BackBtn
