import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackBtn from "../components/backBtn"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <div className="container">
      <h1>Page NOT FOUND</h1>
      <p>Oops! The page you are looking for has been removed or relocated.</p>
      <p>
        Maybe go <Link to="/">Home</Link> and start from there?
      </p>
      <BackBtn text="Go Home" />
    </div>
  </Layout>
)

export default NotFoundPage
